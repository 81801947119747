import { ICoTreatWebChatConfig } from '@/typings';
import { loadPracticeWebChatWidgetConfigs } from '@/services';
import { EPracticeWebChatConfigsPosition } from '@shared/typings/practiceWebChatWidget';
import { EEventTypePracticeWebchat } from './typings/eventTracking';

const DEFAULT_COTREAT_WEBCHAT_CONFIGS: Partial<ICoTreatWebChatConfig> = {
  position: EPracticeWebChatConfigsPosition.right,
  greeting: 'Hi there, have a question? Ask us here, we will text you back.',
  themeColor: '#1890FF',
};

const SCRIPT_TAG_ATTRIBUTE_MAPS = {
  practiceId: 'data-practice-id',
  autoRun: 'data-auto-run',
  autoGreeting: 'data-auto-greeting',
  previewMode: 'data-preview-mode',
  cotreatMobileNumber: 'data-cotreat-mobile-number',
  enableTracking: 'data-enable-tracking',
};

let configsFromScriptTag = null;

export function getConfigsFromScriptTag(): {
  practiceId?: string;
  autoRun?: 'true' | 'false';
  autoGreeting?: 'true' | 'false';
  previewMode?: 'true' | 'false';
  cotreatMobileNumber?: string;
  enableTracking?: 'true' | 'false';
} {
  if (configsFromScriptTag) {
    return configsFromScriptTag;
  }
  const currentScript = document.currentScript;
  if (currentScript) {
    const configs = {};
    Object.keys(SCRIPT_TAG_ATTRIBUTE_MAPS).forEach((configKey: string) => {
      configs[configKey] = currentScript.getAttribute(SCRIPT_TAG_ATTRIBUTE_MAPS[configKey]);
    });
    configsFromScriptTag = configs;
    return configs;
  }
  return {};
}

function loadLocalConfigOverride(config: ICoTreatWebChatConfig): ICoTreatWebChatConfig {
  const localConfig = window.COTREAT_CONFIG;
  const localWebChatConfig = window.COTREAT_CONFIG?.webChat;
  if (window.COTREAT_CONFIG) {
    config.practiceId = localConfig.practiceId;
    config.avatar = localWebChatConfig?.avatar;
    config.position = localWebChatConfig?.position as EPracticeWebChatConfigsPosition;
    config.greeting = localWebChatConfig?.greeting;
    config.themeColor = localWebChatConfig?.themeColor;
    config.previewMode = localWebChatConfig?.previewMode;
    config.cotreatMobileNumber = localWebChatConfig?.cotreatMobileNumber;
  }
  return config;
}

export function getLocalConfigs(): ICoTreatWebChatConfig {
  const scriptConfigs = getConfigsFromScriptTag();
  const defaultConfig: ICoTreatWebChatConfig = {
    practiceId: scriptConfigs.practiceId,
    previewMode: scriptConfigs.previewMode === 'true',
    cotreatMobileNumber: scriptConfigs.cotreatMobileNumber,
    autoGreeting: scriptConfigs.autoGreeting ? scriptConfigs.autoGreeting === 'true' : true,
    ...DEFAULT_COTREAT_WEBCHAT_CONFIGS,
  };
  return loadLocalConfigOverride(defaultConfig);
}

export async function getConfigs(): Promise<ICoTreatWebChatConfig> {
  const { track } = await import('@/services/eventTracking');
  const localConfigs = getLocalConfigs();
  try {
    const practiceConfig = await loadPracticeWebChatWidgetConfigs();
    track(EEventTypePracticeWebchat.webchatConfigLoaded);
    return {
      ...localConfigs,
      ...practiceConfig,
    };
  } catch (error) {
    console.error('Practice WebChat Widget configs load failed, use default setting');
    console.error(error);
    track(EEventTypePracticeWebchat.webchatConfigLoadingErrored);
    return localConfigs;
  }
}
