export enum EEventTypePracticeWebchat {
  webchatScriptExecuted = 'webchat:scriptExecuted',
  webchatInit = 'webchat:init',
  webchatErrorMobileNumberNotConfigured = 'webchat:error:mobileNumberNotConfigured',
  webchatConfigLoadingErrored = 'webchat:configLoadingErrored',
  webchatConfigLoaded = 'webchat:configLoaded',
  webchatBubbleRendered = 'webchat:bubbleRendered',
  webchatBubbleClicked = 'webchat:bubbleClicked',
  webchatGreetingAppeared = 'webchat:greetingAppeared',
  webchatGreetingClosed = 'webchat:greetingClosed',
  webchatGreetingClicked = 'webchat:greetingClicked',
  webchatContactOpened = 'webchat:contact:opened',
  webchatContactClosed = 'webchat:contact:closed',
  webchatContactSubmitted = 'webchat:contact:submitted',
  webchatContactSubmissionError = 'webchat:contact:submissionError',
  webchatContactSuccessViewAppeared = 'webchat:contact:successViewAppeared',
}
