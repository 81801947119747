import React from 'preact/compat';
import { ClassNames, useTheme } from '@emotion/react';
import smileIcon from '@/icons/smile.svg';
import { ITheme } from '@/typings';

export default function Header() {
  const { color: themeColor } = useTheme() as ITheme;

  return (
    <ClassNames>
      {({ css }) => (
        <div
          className={css`
            height: 50px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 16px;
            border-radius: 4px 4px 0 0;
            background: ${themeColor};
          `}
        >
          <img
            className={css`
              width: 24px;
              height: 24px;
              margin-right: 14px;
            `}
            src={smileIcon}
            alt="smile"
          />
          <span>We are here to help</span>
        </div>
      )}
    </ClassNames>
  );
}
