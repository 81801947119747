import React, { useState } from 'preact/compat';
import { ClassNames } from '@emotion/react';
import { JSX } from 'preact';

interface IInputProps {
  icon?: string;
  inputId?: string;
  inputType?: string;
  disabled?: boolean;
  value?: string;
  maxLength?: number;
  placeholder?: string;
  onChange?(value: string): void;
}

export default function Input({
  icon,
  inputId,
  inputType = 'text',
  disabled = false,
  value,
  maxLength = 140,
  placeholder,
  onChange,
}: IInputProps) {
  const [focused,  setFocused] = useState<boolean>(false);

  const handleChange: JSX.GenericEventHandler<HTMLInputElement> = (e) => {
    onChange(e.currentTarget.value);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <ClassNames>
      {({ css, cx }) => {
        const wrapperCls = css`
          border: 1px solid #D9D9D9;
          border-radius: 2px;
          padding: 0 12px;
          display: flex;
          align-items: center;
          transition: all .3s;
          &:hover {
            border-color: #40a9ff;
          }
        `;
        const wrapperFocusedCls = css`
          border-color: #40a9ff;
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
        `;
        const wrapperDisabledCls = css`
          color: rgba(0, 0, 0, 0.25);
          border-color: #d9d9d9;
          background-color: #f5f5f5;
          box-shadow: none;
          cursor: not-allowed;
          &:hover {
            border-color: #d9d9d9;
          }
        `;
        return (
          <div
            className={cx(wrapperCls, {
              [wrapperFocusedCls]: focused,
              [wrapperDisabledCls]: disabled,
            })}
          >
            {icon ? (
              <img
                className={css`
                  width: 14px;
                  height: 14px;
                  margin-right: 6px;
                `}
                src={icon}
                alt="prefix"
              />
            ) : null}
            <input
              id={inputId}
              type={inputType}
              disabled={disabled}
              value={value}
              maxLength={maxLength}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder={placeholder}
              className={css`
                border: none;
                outline: none;
                padding: 8px 0;
                font-size: 14px;
                &[disabled] {
                  color: rgba(0, 0, 0, 0.25);
                  background-color: #f5f5f5;
                  box-shadow: none;
                  cursor: not-allowed;
                }
              `}
            />
          </div>
        );
      }}
    </ClassNames>
  );
}
