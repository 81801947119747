import React, { useState, useEffect } from 'preact/compat';
import { ClassNames } from '@emotion/react';
import Header from './header';
import Send from './send';
import Success from './success';

interface ILayoutProps {
  visible: boolean;
}

export default function Layout({ visible }: ILayoutProps) {
  const [sendPhone, setSendPhone] = useState<string>();
  const [mounted, setMounted] = useState<boolean>(false);
  const onSendSuccess = (phone: string) => {
    setSendPhone(phone);
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <ClassNames>
      {({ css, cx }) => (
        <div className={css`
          padding: 20px;
          height: 100vh;
          width: 100vw;
          box-sizing: border-box;
        `}>
          <div
            className={cx(
              css`
                height: 100%;
                width: 100%;
                z-index: 999999999;
                border-radius: 4px;
                background: #fff;
                overflow: hidden;
                box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
                opacity: 0;
                transform: translateY(20px);
                transition: all 0.2s;
              `,
              mounted && visible && css`
                opacity: 1;
                transform: translateY(0);
              `
            )}
          >
            <Header />
            {sendPhone
              ? <Success sendPhone={sendPhone} />
              : <Send onSendSuccess={onSendSuccess} />
            }
          </div>
        </div>
      )}
    </ClassNames>
  );
}
