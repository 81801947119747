import Autolinker from 'autolinker';

// https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid/2117523#2117523
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    // eslint-disable-next-line
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

/**
 * @return {string}
 */
export function titlelize(str: string) {
  return str
    .trim()
    .replace(/[^a-zA-Z]+/g, ' ')
    .split(' ')
    .map(seg => seg.replace(/\b\w/g, s => s.toUpperCase()))
    .join(' ');
}

export function truncate(str: string, n: number) {
  return (str && str.length > n) ? `${str.substr(0, n - 1)}...` : str;
}

const URL_EX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/ig;
// eslint-disable-next-line max-len
const EMAIL_EX = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/ig;
export function linkURLAndEmail(msg: string) {
  const urlReplaced = (msg || '').replace(URL_EX, matched => {
    return `<a href="${matched}" target="_blank">${matched}</a>`;
  });
  return urlReplaced.replace(EMAIL_EX, matched => {
    return `<a href="mailto:${matched}" target="_blank">${matched}</a>`;
  });
}

// https://github.com/gregjacobs/Autolinker.js#options
export const autolinker = new Autolinker({
  urls: {
    schemeMatches: true,
    wwwMatches: true,
    tldMatches: true,
  },
  email: true,
  phone: true,
  mention: false,
  hashtag: false,
  stripPrefix: false,
  stripTrailingSlash: false,
  newWindow: true,
  truncate: {
    length: 0,
    location: 'end',
  },
  className : '',
});

export function capNumber(count: number, cap = 99) {
  if (count > cap) {
    return `${cap}+`;
  }
  return String(count);
}

export function maskString(
  str: string,
  option: {
    mask: string; keepTrailling: boolean; keepLeading: boolean; maskNumber: number;
  } = { mask: '*', maskNumber: 3, keepLeading: true, keepTrailling: false }
) {
  const trimmedStr = str.trim();
  const strLen = trimmedStr.length;
  let maskStr = '';
  let maskStart = 0;
  let maskEnd = strLen;
  for (let maskCount = 0; maskCount < option.maskNumber; maskCount++) {
    maskStr += option.mask;
  }
  if (option.keepLeading && option.keepTrailling) {
    maskStart = Math.ceil(strLen / 4);
    maskEnd = Math.floor(strLen / 4 * 3);
  } else if (option.keepLeading) {
    maskStart = Math.ceil(strLen / 2);
  } else if (option.keepTrailling) {
    maskEnd = Math.floor(strLen / 2);
  }
  return `${
    trimmedStr.slice(0, maskStart > 3 ? 3 : maskStart)
  }${maskStr}${
    trimmedStr.slice(strLen - maskEnd > 3 ? strLen - 3 : maskEnd)
  }`;
}

export function removeLeadingZeros(str: string) {
  return str ? str.replace(/^0+(?!$)/, '') : '';
}

export function capitalizeFirstLetter(word: string) {
  return word ? `${word.charAt(0).toUpperCase()}${word.slice(1)}` : '';
}
