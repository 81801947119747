import React from 'preact/compat';
import { ClassNames, useTheme } from '@emotion/react';
import IFrameElement from './iframeElement';
import closeIcon from '@/icons/close.svg';
import chatIcon from '@/icons/chat.svg';
import { ITheme } from '@/typings';
import { EPracticeWebChatConfigsPosition } from '@shared/config/practiceWebChatWidget';

interface IBubbleProps {
  position: EPracticeWebChatConfigsPosition;
  isActive: boolean;
  onClick(): void;
}

export default function Bubble({
  position,
  isActive,
  onClick,
}: IBubbleProps) {
  const { color: themeColor } = useTheme() as ITheme;

  return (
    <IFrameElement
      title="CoTreat WebChat Icon"
      style={{
        position: 'fixed',
        zIndex: 2147482995,
        [position]: 20,
        bottom: 20,
        border: 0,
        width: 50,
        height: 50,
      }}
    >
      <ClassNames>
        {({ css, cx }) => {
          const iconCls = css`
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transform: rotate(30deg) scale(0);
            transition: opacity 80ms linear 0s, transform 160ms linear 0s;
          `;
          const iconActiveCls = css`
            opacity: 1;
            transform: rotate(0deg) scale(1);
          `;
          return (
            <div>
              <button
                className={css`
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 50px;
                  height: 50px;
                  color: #fff;
                  padding: 0;
                  border: none;
                  border-radius: 50%;
                  background: ${themeColor};
                  transition: transform 200ms linear 0s;
                  &:hover {
                    cursor: pointer;
                  }
                  &:active {
                    transform: scale(0.9);
                  }
                `}
                onClick={onClick}
              >
                <span
                  className={cx(
                    iconCls,
                    isActive && iconActiveCls
                  )}
                >
                  <img
                    className={css`
                      width: 16px;
                      height: 16px;
                    `}
                    src={closeIcon}
                    alt="close"
                  />
                </span>
                <span
                  className={cx(
                    iconCls,
                    !isActive && iconActiveCls,
                    css`
                      pointer-events: none;
                    `
                  )}
                >
                  <img
                    className={css`
                      width: 22px;
                      height: 22px;
                    `}
                    src={chatIcon}
                    alt="chat"
                  />
                </span>
              </button>
            </div>
          );
        }}
      </ClassNames>
    </IFrameElement>
  );
}
