import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import { Global } from '@emotion/react';
import { styleCSS } from './react-intl-tel-input-style';
import * as phoneNumberUtils from 'awesome-phonenumber';

interface IPropTypes {
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?(phoneNumber: string): void;
}

interface IStateTypes {
  phoneNumber: string;
  dialCode: number;
}

export default class PhoneInput extends React.Component<IPropTypes, IStateTypes> {
  constructor(props) {
    super(props);
    this.state = this.transformValue(props.value);
  }

  transformValue(value = '') {
    let dialCode = this.state?.dialCode || 61;
    const dialCodeWithPlus = `+${dialCode}`;
    let phoneNumber = (value || '').slice(dialCodeWithPlus.length);
    if (value) {
      const phoneNumberObj = phoneNumberUtils.parsePhoneNumber(value);
      if (phoneNumberObj.valid) {
        dialCode = phoneNumberObj.countryCode;
      } else if (value.indexOf(dialCodeWithPlus) !== 0) {
        phoneNumber = value.replace(/\+/g, '');
      }
    }
    return {
      dialCode,
      phoneNumber,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        ...this.transformValue(this.props.value),
      });
    }
  }

  handleChange = (valid, phoneNumber, country) => {
    this.setState({
      phoneNumber,
      dialCode: Number(country.dialCode),
    });
    const trimmedPhoneNumber = phoneNumber.replace(/\s/g, '');
    if (this.props.onChange) {
      if (trimmedPhoneNumber) {
        this.props.onChange(`+${country.dialCode}${phoneNumber.replace(/\s/g, '')}`);
      } else {
        this.props.onChange('');
      }
    }
  };

  render() {
    const { disabled } = this.props;
    const { phoneNumber } = this.state;
    return (
      <div className={`cotreat-phone-input ${disabled ? 'disabled' : ''}`}>
        <Global styles={styleCSS} />
        <IntlTelInput
          disabled={disabled}
          defaultCountry="au"
          preferredCountries={['au']}
          separateDialCode
          value={phoneNumber}
          useMobileFullscreenDropdown={false}
          placeholder={''}
          onPhoneNumberChange={this.handleChange}
        />
      </div>
    );
  }
}
