import React from 'preact/compat';
import { ClassNames } from '@emotion/react';
import { JSX } from 'preact';

interface ITextAreaProps {
  inputId?: string;
  disabled?: boolean;
  value?: string;
  maxLength?: number;
  placeholder?: string;
  onChange?(value: string): void;
}

export default function Input({
  inputId,
  disabled = false,
  value,
  maxLength = 140,
  placeholder,
  onChange,
}: ITextAreaProps) {
  const handleChange: JSX.GenericEventHandler<HTMLTextAreaElement> = (e) => {
    onChange(e.currentTarget.value);
  };

  return (
    <ClassNames>
      {({ css }) => (
        <textarea
          id={inputId}
          disabled={disabled}
          value={value}
          maxLength={maxLength}
          onChange={handleChange}
          placeholder={placeholder}
          className={css`
            padding: 4px 12px;
            font-size: 14px;
            width: 100%;
            height: 90px;
            overflow: auto;
            resize: none;
            outline: none;
            border: 1px solid #D9D9D9;
            transition: all .3s, height 0s;
            &:hover {
              border-color: #40a9ff;
            }
            &:focus {
              border-color: #40a9ff;
              box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            }
            &[disabled] {
              color: rgba(0, 0, 0, 0.25);
              border-color: #d9d9d9;
              background-color: #f5f5f5;
              box-shadow: none;
              cursor: not-allowed;
            }
          `}
        />
      )}
    </ClassNames>
  );
}
