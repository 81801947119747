import React, { useState, useRef } from 'preact/compat';
import { ClassNames, useTheme } from '@emotion/react';
import Form, { IFormRef } from '@/components/form';
import FormItem from '@/components/formItem';
import Input from '@/components/input';
import Textarea from '@/components/textarea';
import userIcon from '@/icons/user.svg';
import { IStoreValue } from '@/components/formContext';
import { sendMessage } from '@/services';
import PhoneInput from '@/components/phoneInput';
import * as phoneNumberUtils from 'awesome-phonenumber';
import { track } from '@/services/eventTracking';
import { ITheme } from '@/typings';
import { EEventTypePracticeWebchat } from '@/typings/eventTracking';

interface ISendProps {
  onSendSuccess(phone: string): void;
}

export default function Send(props: ISendProps) {
  const { onSendSuccess } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const { color: themeColor } = useTheme() as ITheme;
  const formRef = useRef<IFormRef>();

  const onSubmit = async (values: IStoreValue) => {
    setLoading(true);
    track(EEventTypePracticeWebchat.webchatContactSubmitted);
    try {
      const result = await sendMessage({
        name: values.name,
        message: values.message,
        mobileNumber: values.mobileNumber,
      });
      onSendSuccess(result.dedicatedTwilioNumber);
    } catch (error) {
      const typedError = error as Error;
      if (typedError.message.includes('Mobile Number')) {
        formRef.current.setFieldError('mobileNumber', typedError.message);
      }
      console.error(error);
      track(EEventTypePracticeWebchat.webchatContactSubmissionError, {
        error,
      });
    }
    setLoading(false);
  };

  return (
    <ClassNames>
      {({ css }) => (
        <div
          className={css`
            padding: 20px 24px 30px;
          `}
        >
          <div
            className={css`
              padding: 5px 10px 10px;
              text-align: center;
              font-size: 14px;
              font-weight: 400;
              margin-bottom: 12px;
              background: #f0f2f5;
              color: rgba(0, 0, 0, 0.85);
            `}
          >
            <span>Leave us a message and we will text you back shortly</span>
          </div>
          <Form ref={formRef} onSubmit={onSubmit}>
            <FormItem name="name" label="Name" required>
              <Input icon={userIcon} inputType="text" disabled={loading} placeholder="name" />
            </FormItem>
            <FormItem name="message" label="Message" required>
              <Textarea disabled={loading} placeholder="Message" />
            </FormItem>
            <FormItem
              name="mobileNumber"
              label="Mobile number"
              validator={(phoneNumber) => {
                if (!phoneNumber) {
                  return 'Mobile number is required';
                } else {
                  const isValid = phoneNumberUtils.parsePhoneNumber(phoneNumber).valid;
                  if (isValid) {
                    return null;
                  } else {
                    return 'Invalid mobile number';
                  }
                }
              }}
              required
            >
              <PhoneInput disabled={loading} placeholder="mobile number" />
            </FormItem>
            <div
              className={css`
                text-align: center;
                margin: 24px 0;
              `}
            >
              <button
                className={css`
                  padding: 6px 16px;
                  border: 1px solid ${themeColor};
                  border-radius: 2px;
                  color: #fff;
                  background: ${themeColor};
                  &:focus {
                    box-shadow: 0 0 2px 3px ${themeColor};
                  }
                  &[disabled] {
                    color: rgba(0, 0, 0, 0.25);
                    border-color: #d9d9d9;
                    background: #f5f5f5;
                    text-shadow: none;
                    box-shadow: none;
                    cursor: not-allowed;
                  }
                `}
                type="submit"
                disabled={loading}
              >
                Send
              </button>
            </div>
          </Form>
        </div>
      )}
    </ClassNames>
  );
}
