import React, { useState, useEffect } from 'preact/compat';
import IFrameElement from '../iframeElement';
import Layout from './layout';
import { EPracticeWebChatConfigsPosition } from '@shared/config/practiceWebChatWidget';
import { track } from '@/services/eventTracking';
import { EEventTypePracticeWebchat } from '@/typings/eventTracking';

interface IModalProps {
  position: EPracticeWebChatConfigsPosition;
  visible: boolean;
  onClose(): void;
}

export default function Modal({ position, visible, onClose }: IModalProps) {
  const [layoutVisible, setLayoutVisible] = useState<boolean>(false); // Force re-render layout components

  useEffect(() => {
    function handleMouseUp() {
      if (layoutVisible) {
        track(EEventTypePracticeWebchat.webchatContactClosed);
      }
      onClose();
    }
    window.addEventListener('mouseup', handleMouseUp);
    return () => window.removeEventListener('mouseup', handleMouseUp);
  }, [onClose]);

  useEffect(() => {
    if (visible) {
      setLayoutVisible(true);
    } else {
      setTimeout(() => {
        setLayoutVisible(false);
      }, 200);
    }
  }, [visible]);

  if (!layoutVisible) {
    return null;
  }

  useEffect(() => {
    if (visible) {
      track(EEventTypePracticeWebchat.webchatContactOpened);
    } else if (layoutVisible) {
      // if visible is false, and layoutVisible is true
      // it shows it is open and is about to close
      track(EEventTypePracticeWebchat.webchatContactClosed);
    }
  }, [layoutVisible, visible]);

  return (
    <IFrameElement
      title="CoTreat WebChat Contact Form"
      modalVisible={visible}
      style={{
        position: 'fixed',
        zIndex: 2147482997,
        right: 0,
        bottom: 70,
        [position]: 0,
        border: 0,
        width: 325,
        height: 450,
      }}
    >
      <Layout
        key={layoutVisible ? 'visible' : 'closed'}
        visible={visible}
      />
    </IFrameElement>
  );
}
