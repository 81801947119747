export enum EPracticeWebChatConfigsPosition {
  left = 'left',
  right = 'right',
}

export interface IPracticeWebChatConfigs {
  position?: EPracticeWebChatConfigsPosition;
  themeColor?: string;
  greeting?: string;
  avatarFileId?: string;
  avatar?: string;
}
