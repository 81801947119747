import React, { forwardRef, useCallback } from 'preact/compat';
import { Ref } from 'preact';
import { useImperativeHandle, useState, useMemo } from 'preact/hooks';
import { ThemeProvider } from '@emotion/react';
import Modal from './modal';
import Bubble from './bubble';
import Prompt from './prompt';
import { useEffect } from 'react';
import { track } from '@/services/eventTracking';
import { ICoTreatWebChatConfig, ITheme } from '@/typings';
import { EEventTypePracticeWebchat } from '@/typings/eventTracking';

interface IPropTypes {
  configs: ICoTreatWebChatConfig;
}

export interface IWebChatRef {
  refresh(config: ICoTreatWebChatConfig): void;
  reset(): void;
  open(): void;
  close(): void;
}

function WebChat(props: IPropTypes, ref: Ref<IWebChatRef>) {
  const [configs, setConfigs] = useState<ICoTreatWebChatConfig>(props.configs);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [promptVisible, setPromptVisible] = useState<boolean>(props.configs.autoGreeting);
  const theme = useMemo<ITheme>(() => ({ color: configs.themeColor }), [configs]);

  const isPreviewMode = configs.previewMode;

  const openModal = useCallback(() => {
    if (!isPreviewMode) {
      setModalVisible(true);
      setPromptVisible(false);
    }
  }, [isPreviewMode]);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  useImperativeHandle(ref, () => ({
    refresh: (newConfig) => {
      if (newConfig) {
        setConfigs({
          ...configs,
          ...newConfig,
        });
      }
    },
    reset: closeModal,
    open: openModal,
    close: closeModal,
  }), [configs]);

  const handleBubbleClick = useCallback(() => {
    track(EEventTypePracticeWebchat.webchatBubbleClicked);
    if (modalVisible) {
      closeModal();
    } else {
      openModal();
    }
  }, [modalVisible, closeModal, openModal]);

  const handleGreetingClick = useCallback(() => {
    track(EEventTypePracticeWebchat.webchatGreetingClicked);
    openModal();
  }, [openModal]);

  const handleCloseGreeting = useCallback(() => {
    track(EEventTypePracticeWebchat.webchatGreetingClosed);
    setPromptVisible(false);
  }, []);

  useEffect(() => {
    track(EEventTypePracticeWebchat.webchatBubbleRendered);
  }, []);

  useEffect(() => {
    if (promptVisible) {
      track(EEventTypePracticeWebchat.webchatGreetingAppeared);
    }
  }, [promptVisible]);

  return (
    <ThemeProvider theme={theme}>
      <Modal
        onClose={closeModal}
        position={configs.position}
        visible={modalVisible}
      />
      <Bubble
        position={configs.position}
        isActive={modalVisible}
        onClick={handleBubbleClick}
      />
      <Prompt
        showCloseButton={!isPreviewMode}
        avatar={configs.avatar}
        greeting={configs.greeting}
        position={configs.position}
        visible={promptVisible}
        onClick={handleGreetingClick}
        onClose={handleCloseGreeting}
      />
    </ThemeProvider>
  );
}

export default forwardRef(WebChat);
