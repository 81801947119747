import { JSX } from 'preact';
import React, { useEffect, useContext, useCallback, cloneElement } from 'preact/compat';
import { ClassNames } from '@emotion/react';
import FormContext from './formContext';

interface IFormItemProps {
  name: string;
  label: string;
  required?: boolean;
  children: JSX.Element;
  validator?(value: string): string;
}

export default function FormItem({
  name,
  label,
  required = true,
  children,
  validator,
}: IFormItemProps) {
  const { store, fieldError, onFieldChange, registerField } = useContext(FormContext);
  const error = fieldError[name];

  useEffect(() => {
    registerField({
      fieldName: name,
      label,
      required,
      validator,
    });
  }, []);

  const onChange = useCallback((value: string) => {
    onFieldChange(name, value);
  }, []);

  return (
    <ClassNames>
      {({ css }) => (
        <div
          className={css`
            position: relative;
            margin-bottom: 1em;
          `}
        >
          {cloneElement(children, {
            inputId: name,
            value: store[name],
            onChange,
          })}
          {error && (
            <div
              className={css`
                position: absolute;
                width: 100%;
                text-align: right;
                color: #e42837;
                font-size: 12px;
                font-weight: 500;
              `}
            >
              {error}
            </div>
          )}
        </div>
      )}
    </ClassNames>
  );
}
