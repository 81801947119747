import React, { createPortal } from 'preact/compat';
import { useRef, useState, useEffect } from 'preact/hooks';
import createCache, { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { useCallback } from 'react';

// eslint-disable-next-line max-len
const defaultFontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';
const ResetCSS = `
  *, html {
    box-sizing: border-box;
    font-family: ${defaultFontFamily}
  }
  body {
    margin: 0;
  }
  input, button, textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  button {
    outline: 0;
    -webkit-tap-highlight-color: transparent;
  }
`;

interface IFrameElementProps {
  disableRootPageScroll?: boolean;
  modalVisible?: boolean;
  title?: string;
  children: preact.VNode;
  globalCSS?: string;
  style?: Record<string, string | number>;
}

const GLOBAL_IFRAME_STYLE_TAG_ID = 'J_COTREAT_IFRAME_STYLE_TAG';

function IFrameElement(
  { children, title, modalVisible, disableRootPageScroll, globalCSS, ...props }: IFrameElementProps
) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const emotionCacheRef = useRef<EmotionCache>(null);
  const [mountNode, setMountNode] = useState<HTMLElement>();

  // Firefox seems to be very reliable with onload but not that much with useEffect
  // Safari and Chrome's onload does not work in this case...
  function handleIFrameLoad() {
    const iframe = iframeRef.current;
    if (iframe) {
      const doc = iframeRef.current?.contentWindow?.document;
      if (doc) {
        const rendered = doc.querySelector(`#${GLOBAL_IFRAME_STYLE_TAG_ID}`);
        if (!rendered) {
          setMountNode(doc.body);
          emotionCacheRef.current = createCache({
            container: doc.head,
            key: 'iframe-element',
          });
        }
      }
    }
  }

  useEffect(() => {
    const readyState = iframeRef.current?.contentWindow?.document?.readyState;
    if (readyState === 'complete') {
      handleIFrameLoad();
    }
  }, [iframeRef.current]);

  const handleLoad = useCallback(() => {
    const readyState = iframeRef.current?.contentWindow?.document?.readyState;
    if (readyState === 'complete') {
      handleIFrameLoad();
    }
  }, [iframeRef.current]);

  useEffect(() => {
    if (disableRootPageScroll && !!window.parent) {
      if (modalVisible) {
        window.parent.document.body.style.overflow = 'hidden';
      } else {
        window.parent.document.body.style.overflow = 'auto';
      }
    }
  }, [disableRootPageScroll, modalVisible]);

  return (
    <iframe onLoad={handleLoad} title={title || 'iframe'} {...props} ref={iframeRef}>
      {mountNode &&
      emotionCacheRef.current &&
      createPortal(
        <CacheProvider value={emotionCacheRef.current}>
          <>
            <style id={GLOBAL_IFRAME_STYLE_TAG_ID}>
              {ResetCSS}
            </style>
            {globalCSS ? <style>{globalCSS}</style> : null}
            {children}
          </>
        </CacheProvider>,
        mountNode
      )}
    </iframe>
  );
}

export default IFrameElement;
