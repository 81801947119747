import React from 'preact/compat';
import { ClassNames, useTheme } from '@emotion/react';
import checkIcon from '@/icons/check.svg';
import { useEffect } from 'react';
import { track } from '@/services/eventTracking';
import { ITheme } from '@/typings';
import { EEventTypePracticeWebchat } from '@/typings/eventTracking';

interface ISuccessProps {
  sendPhone: string;
}

export default function Success(props: ISuccessProps) {
  const { sendPhone } = props;
  const { color: themeColor } = useTheme() as ITheme;

  useEffect(() => {
    track(EEventTypePracticeWebchat.webchatContactSuccessViewAppeared);
  }, []);

  return (
    <ClassNames>
      {({ css }) => (
        <div
          className={css`
            padding: 26px 24px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.5;
          `}
        >
          <div
            className={css`
              margin-bottom: 20px;
            `}
          >
            <img
              className={css`
                width: 32px;
                height: 32px;
              `}
              src={checkIcon}
              alt="send success"
            />
          </div>
          <h2
            className={css`
              font-size: 14px;
              margin-bottom: 12px;
            `}
          >
            We received your message.
          </h2>
          <p
            className={css`
              color: rgba(0, 0, 0, 0.45);
              margin-bottom: 10px;
            `}
          >
            Our team will text you back from the number below:
          </p>
          <div
            className={css`
              color: ${themeColor};
              padding: 5px;
              background: #F0F2F5;
            `}
          >
            {sendPhone}
          </div>
        </div>
      )}
    </ClassNames>
  );
}
