import React from 'preact/compat';
import { ClassNames } from '@emotion/react';
import IFrameElement from './iframeElement';
import { isTouchDevice } from '@shared/utils/device';
import { EPracticeWebChatConfigsPosition } from '@shared/config/practiceWebChatWidget';

interface IPromptProps {
  avatar: string;
  greeting: string;
  position: EPracticeWebChatConfigsPosition;
  visible: boolean;
  showCloseButton?: boolean;
  onClick(): void;
  onClose(): void;
}

export default function Prompt(
  {
    avatar,
    greeting,
    position,
    visible,
    onClick,
    onClose,
    showCloseButton,
  }: IPromptProps
) {
  if (!visible) {
    return null;
  }

  return (
    <IFrameElement
      title="CoTreat WebChat Greeting"
      style={{
        position: 'fixed',
        zIndex: 2147482995,
        [position]: 0,
        bottom: 70,
        border: 0,
        width: 320,
        height: 140,
      }}
    >
      <ClassNames>
        {({ css }) => (
          <div
            className={css`
              bottom: 0;
              padding: 40px 20px 20px 20px;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              &:hover {
                button {
                  opacity: 1;
                }
              }
            `}
          >
            {showCloseButton ? (
              <button
                className={css`
                  background-color: #fff;
                  border-radius: 99999px;
                  border: none;
                  color: #767676;
                  cursor: pointer;
                  position: absolute;
                  top: 10px;
                  right: 20px;
                  font-size: 12px;
                  font-weight: 500;
                  padding: 5px 15px;
                  margin-right: 3px;
                  margin-bottom: 4px;
                  opacity: ${isTouchDevice() ? 1 : 0};
                  &:hover {
                    color: #000;
                    background-color: #f0f0f0;
                  }
                  transition: all .25s;
                `}
                onClick={onClose}
              >
                close
              </button>
            ) : null}
            <div
              className={css`
                display: flex;
                flex-direction: row;
                width: 100%;
                padding: 20px 15px;
                height: 80px;
                overflow: hidden;
                background: #fff;
                box-shadow: 0 1px 20px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                cursor: pointer;
              `}
              onClick={onClick}
            >
              {avatar ? (
                <div
                  className={css`
                    flex: none;
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                    margin-right: 16px;
                    background-color: #e4e9f0;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: 50%;
                    ${avatar ? `background-image: url(${avatar})` : ''}
                  `}
                />
              ) : null}
              <div
                className={css`
                  font-size: 14px;
                  color: rgba(0, 0, 0, 0.85);
                `}
              >
                {greeting}
              </div>
            </div>
          </div>
        )}
      </ClassNames>
    </IFrameElement>
  );
}
