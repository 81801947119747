// previously using mixpanel, keeping code for future reference

import { IUser } from '@shared/typings';
import { maskString } from '@shared/utils/string';
import superAgent from 'superagent';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function noop(...args: any) {
  // do nothing
}

export const UUID_EX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/ig;

export function track(eventName: string, property: Record<string, any> = {}) {
  // return .track(eventName, property);
  noop(eventName, property);
}

export function getUserDataForTracking(user: IUser) {
  return {
    // eslint-disable-next-line camelcase
    $first_name: maskString(user.firstName),
    // eslint-disable-next-line camelcase
    $last_name: maskString(user.lastName),
    id: user.id,
  };
}

export function getPracticeDataForTracking(practice: { id: string; name: string }) {
  return {
    practiceId: practice.id,
    practiceName: maskString(practice.name, { mask: '*', maskNumber: 3, keepLeading: true, keepTrailling: true }),
  };
}

export function identify(user: IUser, option: { includePracticeInfo?: true } = {}) {
  // .identify(user.id);
  const identifyData = {
    ...getUserDataForTracking(user),
    ...(user.practice && option.includePracticeInfo ? getPracticeDataForTracking(user.practice) : {}),
  };
  // .people.set(identifyData);
  // .register(identifyData);
  noop(identifyData);
}

export function attachPracticeDataToEvent(practice: { id: string; name: string }) {
  // .register(getPracticeDataForTracking(practice));
  noop(practice);
}

export function unattachPracticeDataForEvent() {
  // .unregister('practiceId');
  // .unregister('practiceName');
}

export function attachEventData(data: Record<string, any>) {
  // .register(data);
  noop(data);
}

export function reset() {
  // .reset();
}

export function sendEventToLoggingService(loggingServiceUrl: string, data = {}) {
  const newHeaders = {
    'X-Requested-With': 'XMLHttpRequest',
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'authorization': null,
  };

  return superAgent.post(loggingServiceUrl)
    .set(newHeaders)
    .send(data)
    .catch(console.error);
}
