import React from 'preact/compat';

export interface IStoreValue {
  [fieldName: string]: string;
}

export interface IFieldError {
  [fieldName: string]: string;
}

export interface IFieldEntity {
  fieldName: string;
  label: string;
  required?: boolean;
  validator?(value: string): string;
}

export interface IFormContextProps {
  store: IStoreValue;
  fieldEntities: IFieldEntity[];
  fieldError: IFieldError;
  registerField?(entity: IFieldEntity): void;
  onFieldChange?(fieldName: string, value: string): void;
  resetFieldError?(fieldName?: string): void;
}

const FormContext = React.createContext<IFormContextProps>({
  store: {},
  fieldEntities: [],
  fieldError: {},
});

export default FormContext;
