import { JSX, Ref } from 'preact';
import React, { forwardRef, useState, useCallback, useRef, useImperativeHandle } from 'preact/compat';
import FormContext, { IStoreValue, IFieldEntity, IFieldError } from './formContext';

interface IFormProps {
  children: JSX.Element[];
  onSubmit?(value: IStoreValue): void;
}

export interface IFormRef {
  setFieldError(fieldName: string, error: string): void;
}

function Form(props: IFormProps, ref: Ref<IFormRef>) {
  const { onSubmit, children } = props;
  const submitting = useRef<boolean>(false);
  const [store, setStore] = useState<IStoreValue>({});
  const [fieldEntities, setFieldEntities] = useState<IFieldEntity[]>([]);
  const [fieldError, setFieldsError] = useState<IFieldError>({});

  const registerField = useCallback((entity: IFieldEntity) => {
    setFieldEntities((prevFieldEntities) => (
      [...prevFieldEntities, entity]
    ));
  }, []);

  const setFieldError = useCallback((fieldName: string, error: string) => {
    setFieldsError((prevfieldError) => (
      { ...prevfieldError, [fieldName]: error }
    ));
  }, []);

  const resetFieldError = useCallback((fieldName?: string) => {
    if (!fieldName) {
      setFieldsError({});
      return;
    }
    setFieldsError((prevfieldError) => (
      { ...prevfieldError, [fieldName]: '' }
    ));
  }, []);

  const onFieldChange = useCallback((fieldName: string, value: string) => {
    resetFieldError(fieldName);
    setStore((prevStore) => (
      { ...prevStore, [fieldName]: value }
    ));
  }, []);

  const handleSubmit: JSX.GenericEventHandler<HTMLFormElement> = useCallback((event) => {
    event.preventDefault();
    if (submitting.current) {
      return;
    }
    submitting.current = true;
    const error: IFieldError = {};
    fieldEntities.forEach(entity => {
      const { fieldName, label, required, validator } = entity;
      const value = store[fieldName];
      if (required && !value) {
        error[fieldName] = `${label} is required`;
      } else if (validator) {
        error[fieldName] = validator(value);
      }
    });
    setFieldsError(error);
    const errorOccurred = Object.keys(error).some(errKey => error[errKey]);
    if (!errorOccurred) {
      onSubmit(store);
    }
    submitting.current = false;
  }, [store, fieldEntities]);

  useImperativeHandle(ref, () => ({
    setFieldError,
  }), []);

  return (
    <form noValidate onSubmit={handleSubmit}>
      <FormContext.Provider
        value={{
          store,
          fieldEntities,
          fieldError,
          onFieldChange,
          registerField,
          resetFieldError,
        }}
      >
        {children}
      </FormContext.Provider>
    </form>
  );
}

export default forwardRef<IFormRef, IFormProps>(Form);
