import React, { createRef } from 'preact/compat';
import { render } from 'preact';
import WebChat, { IWebChatRef } from './webChat';
import { getConfigs, getConfigsFromScriptTag } from '@/config';
import { ICoTreatWebChatConfig } from '@/typings';
import { track, attachEventData } from '@/services/eventTracking';
import { EEventTypePracticeWebchat } from './typings/eventTracking';

const configsFromScriptTag = getConfigsFromScriptTag();
const autoRunByDefault = configsFromScriptTag.autoRun === 'true';
const webChatRef = createRef<IWebChatRef>();

// use practiceId not directly from scriptTag, in case there's other global config placed
attachEventData({
  practiceId: configsFromScriptTag.practiceId,
});
track(EEventTypePracticeWebchat.webchatScriptExecuted, {
  autoRunByDefault,
});

export async function init(configs?: ICoTreatWebChatConfig) {
  track(EEventTypePracticeWebchat.webchatInit);
  const widgetConfigs = configs || await getConfigs();
  if (widgetConfigs.cotreatMobileNumber) {
    render(
      <WebChat
        configs={widgetConfigs}
        ref={webChatRef} />,
      document.body
    );
  } else {
    track(EEventTypePracticeWebchat.webchatErrorMobileNumberNotConfigured);
    throw new Error('Practice does not have a dedicated CoTreat Number setup yet, WebChat widget will not run');
  }
}

export function refresh(configs?: ICoTreatWebChatConfig) {
  if (webChatRef.current) {
    webChatRef.current.refresh(configs);
  }
}

export function open() {
  if (webChatRef.current) {
    webChatRef.current.open();
  }
}

export function close() {
  if (webChatRef.current) {
    webChatRef.current.close();
  }
}

export function reset() {
  if (webChatRef.current) {
    webChatRef.current.reset();
  }
}

if (autoRunByDefault) {
  setTimeout(init, 0);
}
