import 'whatwg-fetch';
import { getLocalConfigs } from '@/config';
import { ISendFormData } from '@/typings';
import { COTREAT_API_SERVER_HOST } from '@shared/config/env';

export interface IOptions {
  [field: string]: any;
  url: string;
}

export default function request(options: IOptions) {
  const localConfigs = getLocalConfigs();
  const { practiceId } =  localConfigs;
  if (!practiceId) {
    throw new Error('CoTreat WebChat widget failed to initialize: Practice Id is not configured.');
  }
  // Practice Id Must Be Set Previously
  const {
    url,
    method = 'GET',
    headers = {},
    body,
  } = options;
  const newOptions = { method, body, headers };
  const methodArr = ['POST', 'PUT', 'DELETE'];
  if (methodArr.indexOf(method) > -1) {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      // newOptions.body is FormData
      newOptions.headers = {
        Accept: 'application/json',
        ...newOptions.headers,
      };
    }
  }

  return window.fetch(
    `${COTREAT_API_SERVER_HOST}/webChatWidget/${practiceId}${url}`,
    newOptions
  ).then(response => {
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }
    return response.text().then(errorMsg => {
      throw new Error(errorMsg);
    });
  });
}

export function loadPracticeWebChatWidgetConfigs() {
  return request({
    url: '/configs',
  });
}

export function sendMessage(formdata: ISendFormData ) {
  return request({
    url: '/conversations',
    method: 'POST',
    body: formdata,
  });
}
