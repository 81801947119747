import * as eventTracking from '@shared/services/eventTracking';
import { getConfigsFromScriptTag } from '@/config';

const configsFromScriptTag = getConfigsFromScriptTag();

export function track(...args: Parameters<typeof eventTracking.track>) {
  if (
    configsFromScriptTag.enableTracking === 'true'
    && configsFromScriptTag.previewMode !== 'true'
  ) {
    eventTracking.track(...args);
  }
}

export const attachEventData = eventTracking.attachEventData;
